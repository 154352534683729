export const rotor1 = {
  encryption: {
    a: "E",
    b: "K",
    c: "M",
    d: "F",
    e: "G",
    f: "D",
    g: "Q",
    h: "V",
    i: "Z",
    j: "N",
    k: "T",
    l: "O",
    m: "A",
    n: "W",
    o: "Y",
    p: "H",
    q: "X",
    r: "U",
    s: "S",
    t: "P",
    u: "I",
    v: "B",
    w: "R",
    x: "C",
    y: "J",
    z: "L",
  },
  triggerIndex: 2,
}

export const rotor2 = {
  encryption: {
    a: "K",
    b: "T",
    c: "A",
    d: "D",
    e: "F",
    f: "V",
    g: "L",
    h: "E",
    i: "P",
    j: "Q",
    k: "O",
    l: "H",
    m: "C",
    n: "Z",
    o: "B",
    p: "W",
    q: "Y",
    r: "S",
    s: "X",
    t: "U",
    u: "M",
    v: "G",
    w: "N",
    x: "R",
    y: "J",
    z: "I",
  },
  triggerIndex: 10,
}

export const rotor3 = {
  encryption: {
    a: "L",
    b: "V",
    c: "D",
    d: "Q",
    e: "Z",
    f: "M",
    g: "F",
    h: "E",
    i: "B",
    j: "X",
    k: "P",
    l: "T",
    m: "O",
    n: "H",
    o: "C",
    p: "K",
    q: "Y",
    r: "G",
    s: "N",
    t: "W",
    u: "J",
    v: "A",
    w: "U",
    x: "R",
    y: "S",
    z: "I",
  },
  triggerIndex: 8,
}
